/* eslint-disable */
require('script-loader!file-saver');
// require('script-loader!./Blob');
const {
    blob
} = require('./Blob')
require('script-loader!xlsx/dist/xlsx.core.min');


function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}



export function export_json_to_excel(th, data, defaultTitle, merges) {

    /* original data */
    //多表头
    if (typeof th[0] !== 'string') {
        for (let i = th.length - 1; i >= 0; i--) {
            data.unshift(th[i])
        }
    } else {
        data.unshift(th);
    }
    var ws_name = "Sheet1";

    var wb = new Workbook();
    var ws = XLSX.utils.aoa_to_sheet(data);
    //合并单元格
    if (merges) {
        ws['!merges'] = merges
    }
    // ws["!merges"] = [{ //合并A1A2单元格
    //     s: { //s为开始
    //         c: 0, //开始列
    //         r: 0 //开始行
    //     },
    //     e: { //e结束
    //         c: 0, //结束列
    //         r: 1 //结束行
    //     }
    // }]

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
    });
    var title = defaultTitle || '列表'
    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), title + ".xlsx")
}